import React from 'react'

const Header = ({ change, web3m, provider1 }) => {

    return (
        <React.Fragment>
            
        </React.Fragment>
    );
};

export default Header;